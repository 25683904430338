import React from "react"
import Layout from "../components/Layouts/base"
import { PersonalInfoShortForm } from "../components/PersonalInfoForm/ShortApp"

export default function Page() {
  return (
    <Layout>
      <PersonalInfoShortForm hasESIA={false} />
    </Layout>
  )
}
